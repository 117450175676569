<ng-template #currentTimeMarkerTemplate let-columnDate="columnDate" let-dayStartHour="dayStartHour"
  let-dayStartMinute="dayStartMinute" let-dayEndHour="dayEndHour" let-dayEndMinute="dayEndMinute"
  let-isVisible="isVisible" let-topPx="topPx">
  <div class="cal-current-time-marker" *ngIf="isVisible" [style.top.px]="topPx"></div>
</ng-template>

<ng-template #weekViewHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked"
  let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
  <div class="cal-day-headers" role="row">
    <div class="cal-header" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass" (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })" mwlDroppable
      dragOverClass="cal-drag-over" (drop)="
        eventDropped.emit({
          event: $event.dropData.event,
          newStart: day.date
        })
      " (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0" role="columnheader">
      <b>{{ day.date | calendarDate:'weekViewColumnHeader':locale }}</b><br>
      {{ day.date | date : 'd MMMM' }}
    </div>
  </div>
</ng-template>

<ng-template #weekViewHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel">
  <div #segmentElement class="cal-hour-segment" [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass" [class.disabled]="disabled"
    (mousedown)="startDragToCreateTimeSlot(segment, $event, segmentElement)">
    <div class="cal-time" *ngIf="isTimeLabel">
      {{ segment.date | calendarDate:'weekViewHour':locale }}
    </div>
  </div>
</ng-template>

<ng-template #weekViewEventTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column" let-daysInWeek="daysInWeek">
  <div class="cal-event" [ngStyle]="{
      backgroundColor: weekEvent.event.color?.secondary,
      borderColor: weekEvent.event.color?.secondary,
      color: weekEvent.event.color?.primary
    }" [mwlCalendarTooltip]="
      !tooltipDisabled
        ? (weekEvent.event.title
          | calendarEventTitle
            : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
            : weekEvent.tempEvent || weekEvent.event)
        : ''
    " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
    [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
    [contextMenu]="timeSlotMenu" [contextMenuSubject]="weekEvent.event"
    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
    (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })" tabindex="0" role="application" [attr.aria-label]="
      { event: weekEvent.tempEvent || weekEvent.event, locale: locale }
        | calendarA11y: 'eventDescription'
    ">
    <mwl-calendar-event-title [event]="weekEvent.tempEvent || weekEvent.event"
      [view]="daysInWeek === 1 ? 'day' : 'week'">
    </mwl-calendar-event-title>
    <mwl-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event">
    </mwl-calendar-event-actions>
  </div>
</ng-template>

<div>
  <button mat-icon-button [matTooltip]="'PREVIOUS' | translate" [matTooltipPosition]="'above'" mwlCalendarPreviousView
    [(viewDate)]="viewDate" [view]="view" (click)="$event.preventDefault()">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <ng-container [ngSwitch]="view">
    <ng-container *ngSwitchCase="'week'">
      {{ this.viewPeriod?.start | date : 'd MMMM' }} - {{ this.viewPeriod?.end | date : 'd MMMM YYYY' }}
    </ng-container>
    <ng-container *ngSwitchCase="'day'">
      {{ this.viewPeriod?.start | date : 'd MMMM' }}
    </ng-container>
  </ng-container>
  <button mat-icon-button [matTooltip]="'NEXT' | translate" [matTooltipPosition]="'above'" mwlCalendarNextView
    [(viewDate)]="viewDate" [view]="view" (click)="$event.preventDefault()">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
<ng-container [ngSwitch]="view">
  <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="calendarEvents" [locale]="locale"
    [refresh]="refresh$" [currentTimeMarkerTemplate]="currentTimeMarkerTemplate" [weekStartsOn]="weekStartsOn"
    [snapDraggedEvents]="true" [hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight"
    [headerTemplate]="weekViewHeaderTemplate" [hourSegmentTemplate]="weekViewHourSegmentTemplate"
    [eventTemplate]="weekViewEventTemplate" (eventTimesChanged)="eventTimesChanged($event)"
    (eventClicked)="eventClicked($event)" (beforeViewRender)="beforeWeekViewRender($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="calendarEvents" [locale]="locale"
    [refresh]="refresh$" [currentTimeMarkerTemplate]="currentTimeMarkerTemplate" [snapDraggedEvents]="false"
    [hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight"
    [hourSegmentTemplate]="weekViewHourSegmentTemplate" [eventTemplate]="weekViewEventTemplate"
    (eventTimesChanged)="eventTimesChanged($event)" (eventClicked)="eventClicked($event)"
    (beforeViewRender)="updateViewPeriod($event)">
  </mwl-calendar-day-view>
</ng-container>