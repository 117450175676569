export enum LicenseTypeEnum {
  Quota = "Quota",
  Subscription = "Subscription"
}
export interface LicenseType {
  id: string
  appId: string
  name: string
  type: LicenseTypeEnum
  start: Date
  end: Date
  quota: number
  period: string
  trial: boolean
}
