<mat-dialog-content class="time-slot-form">
  <form class="time-slot-form__form" [formGroup]="timeSlotForm" (ngSubmit)="onSubmit()">

    <mat-checkbox class="time-slot-form__form__input time-slot-form__form__input__checkbox" formControlName="allDay">
      {{ 'SCHEDULE.TIME_SLOT.ALL_DAY' | translate }}
    </mat-checkbox>

    <ngx-mat-timepicker-field formControlName="startTime" *ngIf="!timeSlotForm.get('allDay')?.value"
      [defaultTime]="'08:00 AM'" [format]="24" floatLabel="auto">
    </ngx-mat-timepicker-field>
    <mat-error *ngIf="timeSlotForm.get('startTime')?.invalid">
      <app-validator-errors [errors]="timeSlotForm.get('startTime')?.errors"></app-validator-errors>
    </mat-error>

    <ngx-mat-timepicker-field formControlName="endTime" *ngIf="!timeSlotForm.get('allDay')?.value"
      [defaultTime]="'12:00 AM'" [format]="24" floatLabel="auto">
    </ngx-mat-timepicker-field>
    <mat-error *ngIf="timeSlotForm.get('endTime')?.invalid">
      <app-validator-errors [errors]="timeSlotForm.get('endTime')?.errors"></app-validator-errors>
    </mat-error>

    <mat-radio-group formControlName="type" (change)="onTypeChanged()"
      class="time-slot-form__form__input--need-space-after">
      <mat-radio-button [value]="TimeSlotType.WEEKLY">{{ 'SCHEDULE.TIME_SLOT.WEEKLY' | translate }}</mat-radio-button>
      <mat-radio-button [value]="TimeSlotType.ONESHOT">{{ 'SCHEDULE.TIME_SLOT.ONE_SHOT' | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <ng-container *ngIf="timeSlotForm.get('type')?.value == TimeSlotType.ONESHOT">
      <mat-form-field class="time-slot-form__form__input">
        <mat-label>{{ 'SCHEDULE.TIME_SLOT.BROADCASTING_PERIOD' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker"
          [disabled]="timeSlotForm.get('type')?.value != TimeSlotType.ONESHOT">
          <input matStartDate formControlName="startDate" [placeholder]="'SCHEDULE.TIME_SLOT.START_DATE' | translate">
          <input matEndDate formControlName="endDate" [placeholder]="'SCHEDULE.TIME_SLOT.END_DATE' | translate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-datepicker-toggle matSuffix (click)="clearDates()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-error *ngIf="timeSlotForm.get('startDate')?.invalid || timeSlotForm.get('endDate')?.invalid"
        class="time-slot-form__form__input__period__error">
        <app-validator-errors
          [errors]="timeSlotForm.get('startDate')?.errors ? timeSlotForm.get('startDate')?.errors : timeSlotForm.get('endDate')?.errors">
        </app-validator-errors>
      </mat-error>
    </ng-container>

    <ng-container *ngIf="timeSlotForm.get('type')?.value == TimeSlotType.WEEKLY">
      <div class="time-slot-form__form__input">
        <mat-button-toggle-group formControlName="weekDays" multiple="true" [hideMultipleSelectionIndicator]="true">
          <mat-button-toggle *ngFor="let day of weekDays" [value]="day">{{ moment.weekdaysShort(day) }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-error *ngIf="timeSlotForm.get('weekDays')?.invalid">
        <app-validator-errors [errors]="timeSlotForm.get('weekDays')?.errors"></app-validator-errors>
      </mat-error>
    </ng-container>

  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button type="button" class="hcl-button" (click)="onClose()">
    {{ 'CANCEL' | translate }}
  </button>
  <span class="fill-remaining-space"></span>
  <button mat-button type="button" class="hcl-button-delete" id="deleteTimeSlotButton" (click)="doDelete()">
    {{ 'DELETE' | translate }}
  </button>
  <button mat-button type="submit" class="scr-button-confirm" (click)="onSubmit()" [disabled]="!timeSlotForm.valid">
    {{ 'SAVE' | translate }}
  </button>
</mat-dialog-actions>