<mat-toolbar color="accent">
  <button mat-raised-button (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>{{ 'BACK' | translate }}
  </button>
  <button type="button" class="customer-details__migrate-btn" mat-raised-button
          (click)="openCustomerMigrateDialog()"
          *cmnIfPerm="['cmn:customer:update:parent']">
    {{ "DEVICE.MIGRATE.BUTTON" | translate }}
  </button>
  <span class="fill-remaining-space"></span>
</mat-toolbar>
<div *ngIf="customer && apps && sellingCompanies" class="customer-details">
  <div class="customer-details__header">
    <mat-card-header>
      <mat-card-title>{{ 'CUSTOMER_DETAIL_COMPONENT.TITLE' | translate}}{{ ': ' + customer.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-actions align="end">
      <button mat-raised-button color="accent" [disabled]="customerForm.invalid || !canUpdate"
        (click)="updateCustomer()">
        <mat-icon>done</mat-icon>
        {{'SAVE' | translate}}
      </button>
    </mat-card-actions>
  </div>
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab>
      <ng-template mat-tab-label>{{'DEVICE_DETAILS.TABS.DETAILS' | translate}}</ng-template>
      <customer-fields [customer]="customer" [parentForm]="customerForm" [namePlaceholder]="namePlaceholder">
      </customer-fields>
    </mat-tab>
    <mat-tab *cmnIfPerm="['cmn:customer:update:admin'];op:'OR'">
      <ng-template mat-tab-label>{{'ADMINISTRATION' | translate}}</ng-template>
      <customer-admin-fields [customer]="customer" [apps]="apps" [sellingCompanies]="sellingCompanies"
        [parentForm]="customerForm">
      </customer-admin-fields>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{'HOURS' | translate}}</ng-template>
      <mat-card class="customer-details__calendar">
        <mat-card-header>
          <mat-card-title>{{ "OPENING_HOURS.TITLE" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-calendar #openingHoursCalendar [timeSlots]="customer.openingTimeSlots!"></app-calendar>
        </mat-card-content>
      </mat-card>
      <mat-card class="customer-details__calendar">
        <mat-card-header>
          <mat-card-title>{{ "UPDATING_TIME_SLOTS.TITLE_CUSTOMER" | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <app-calendar #updatingHoursCalendar [timeSlots]="customer.updatingTimeSlots!"></app-calendar>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab *cmnIfPerm="['cmn:customer:update:theme']">
      <ng-template mat-tab-label>{{'THEME' | translate}}</ng-template>
      <theme-tab [parentForm]="customerForm" [customer]="customer"></theme-tab>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>{{'METADATA' | translate}}</ng-template>
      <pre>{{ customer.metadata | json }}</pre>
    </mat-tab>
  </mat-tab-group>
</div>
