import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, inject, Directive, Output, NgModule } from '@angular/core';
const GOOGLE_MAPS_SDK_SCRIPT_ID = 'google-maps-js-sdk';
const defaultLoaderOptions = {
  libraries: ['places'],
  id: GOOGLE_MAPS_SDK_SCRIPT_ID
};
const defaultOptions = {
  fields: ['address_components'],
  types: ['address']
};
const GOOGLE_MAPS_SDK_CONFIG = new InjectionToken('GOOGLE_MAPS_SDK_CONFIG');
const GOOGLE_MAPS_SDK_FACTORY = new InjectionToken('GOOGLE_MAPS_SDK');
class PlacesAutocompleteDirective {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.placeChanged = new EventEmitter();
    this.config = inject(GOOGLE_MAPS_SDK_CONFIG);
    this.mapsSdkFactory = inject(GOOGLE_MAPS_SDK_FACTORY, 8);
  }
  ngAfterViewInit() {
    this.init();
  }
  registerAutocomplete(googleMaps) {
    this.autocomplete = new googleMaps.maps.places.Autocomplete(this.elementRef.nativeElement, this.config.options);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete?.getPlace();
      if (place) {
        this.placeChanged.emit(place);
      }
    });
  }
  async init() {
    const googleLoaded = typeof google !== 'undefined' && google?.maps?.places?.Autocomplete;
    // Reuse the existing `window.google` if it exists otherwise load it
    if (googleLoaded) {
      this.registerAutocomplete(google);
    } else if (this.mapsSdkFactory) {
      await this.mapsSdkFactory(this.config);
      this.registerAutocomplete(google);
    } else {
      if (this.config.devMode) {
        console.error(`Google Maps SDK has not been loaded. The placesAutocomplete directive will not work correctly.`);
      }
    }
  }
  static {
    this.ɵfac = function PlacesAutocompleteDirective_Factory(t) {
      return new (t || PlacesAutocompleteDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PlacesAutocompleteDirective,
      selectors: [["input", "placesAutocomplete", ""]],
      outputs: {
        placeChanged: "placeChanged"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PlacesAutocompleteDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'input[placesAutocomplete]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    placeChanged: [{
      type: Output
    }]
  });
})();
class PlacesAutocompleteModule {
  static {
    this.ɵfac = function PlacesAutocompleteModule_Factory(t) {
      return new (t || PlacesAutocompleteModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PlacesAutocompleteModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PlacesAutocompleteModule, [{
    type: NgModule,
    args: [{
      declarations: [PlacesAutocompleteDirective],
      exports: [PlacesAutocompleteDirective]
    }]
  }], null, null);
})();
function providePlacesAutocomplete(config) {
  return {
    provide: GOOGLE_MAPS_SDK_CONFIG,
    useValue: {
      ...config,
      options: {
        ...defaultOptions,
        ...(config.options ?? {})
      },
      loaderOptions: {
        ...defaultLoaderOptions,
        ...(config.loaderOptions ?? {})
      }
    }
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { GOOGLE_MAPS_SDK_CONFIG, GOOGLE_MAPS_SDK_FACTORY, GOOGLE_MAPS_SDK_SCRIPT_ID, PlacesAutocompleteDirective, PlacesAutocompleteModule, defaultLoaderOptions, defaultOptions, providePlacesAutocomplete };
