<h1 mat-dialog-title>{{ "CUSTOMER.MIGRATE.TITLE" | translate }}</h1>
<mat-dialog-content>
  <div>
    <span>{{ "CUSTOMER.MIGRATE.CURRENT_CUSTOMER" | translate }}</span>
    <span class="customer-migrate-dialog__desc-value"><b>{{ customer.name }}<span
      *ngIf="customer.displayName"> / {{
      customer.displayName }}</span></b></span>
  </div>
  <div *ngIf="currentParentCustomer">
    <span>{{ "CUSTOMER.MIGRATE.CURRENT_PARENT" | translate }}</span>
    <span class="customer-migrate-dialog__desc-value"><b>{{ currentParentCustomer.name }}<span
      *ngIf="currentParentCustomer.displayName"> / {{
      currentParentCustomer.displayName }}</span></b></span>
  </div>
  <form [formGroup]="custMigrateForm">
    <app-customer-recent-search-select class="customer-migrate-dialog__customer-recent-search-select"
                                       [placeholder]="'CUSTOMER.MIGRATE.SELECT_CUSTOMER_PLACEHOLDER' | translate"
                                       (selectedCustomerChange)="onSelectedCustomer($event)">
    </app-customer-recent-search-select>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close type="button">
    {{ "CANCEL" | translate }}
  </button>
  <button mat-raised-button color="primary" [disabled]="!custMigrateForm.valid" (click)="confirm()">
    {{ "VALIDATE" | translate }}
  </button>
</mat-dialog-actions>
