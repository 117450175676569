import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Customer, ScopeService } from 'hcl-lib';
import { Media, MediaType } from 'projects/hcl-portal/src/app/common/interfaces/media';
import { MediaService } from 'projects/hcl-portal/src/app/common/services/media/media.service';
import { Observable, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MediaPickerDialogComponent } from '../../media-picker-dialog/media-picker-dialog.component';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'theme-tab',
  templateUrl: './theme-tab.component.html',
  styleUrls: ['./theme-tab.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCardModule, MatButtonModule, MatIconModule, MatTooltipModule, TranslateModule]
})
export class ThemeTabComponent implements OnInit {

  @Input() customer!: Customer
  @Input() parentForm!: UntypedFormGroup

  subscriptions: Subscription = new Subscription()
  whiteLabelLogoPreviewUrl?: string
  pluginLogoPreviewUrl?: string
  isScopeCustomer!: boolean

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private mediaService: MediaService,
    private scopeService: ScopeService
  ) {
  }

  ngOnInit(): void {
    this.isScopeCustomer = this.customer.id == this.scopeService.getScope()

    this.parentForm.addControl('displayName', this.formBuilder.control(this.customer.displayName))
    this.parentForm.addControl('whiteLabelLogoMediaId', this.formBuilder.control(this.customer.whiteLabelLogoMediaId))
    this.parentForm.addControl('pluginLogoMediaId', this.formBuilder.control(this.customer.pluginLogoMediaId))
    this.parentForm.addControl('domainSegmentName', this.formBuilder.control(this.customer.domainSegmentName))
    this.parentForm.addControl('realmName', this.formBuilder.control(this.customer.realmName))
    this.parentForm.addControl('scrUserGuideUrl', this.formBuilder.control(this.customer.scrUserGuideUrl))

    if (this.customer.whiteLabelLogoMediaId) {
      this.retrieveWhiteLabelLogo(this.customer.whiteLabelLogoMediaId)
    }
    if (this.customer.pluginLogoMediaId) {
      this.retrievePluginLogo(this.customer.pluginLogoMediaId)
    }
  }

  retrieveMedia(mediaId: string): Observable<Media> {
    return this.scopeService.getScopeCustomer().pipe(
      mergeMap(customer => {
        let customerId = null
        if (this.customer.id != customer?.id) {
          customerId = this.customer.id
        }
        return this.mediaService.getMedia(mediaId, customerId as string)
      })
    )
  }

  retrieveWhiteLabelLogo(mediaId: string): void {
    this.subscriptions.add(
      this.retrieveMedia(mediaId).subscribe(media => {
        this.whiteLabelLogoPreviewUrl = media.thumbnailUrl
      })
    )
  }

  retrievePluginLogo(mediaId: string): void {
    this.subscriptions.add(
      this.retrieveMedia(mediaId).subscribe(media => {
        this.pluginLogoPreviewUrl = media.thumbnailUrl
      })
    )
  }

  openMediaPicker(): Observable<Media> {
    var config = new MatDialogConfig()
    config.data = {
      mediaTypes: [MediaType.Image],
      publicMediaTypes: [MediaType.Image],
      actionLabel: this.translateService.instant("MEDIA_PICKER_DIALOG.LABEL.ADD"),
      hidePublicMediasTab: true,
      hideActivityMediasTab: true
    }
    return this.matDialog.open(MediaPickerDialogComponent, config).afterClosed()
  }

  changeWhiteLabelLogo(): void {
    this.subscriptions.add(
      this.openMediaPicker().subscribe(media => {
        if (media) {
          this.whiteLabelLogoPreviewUrl = (media as Media).thumbnailUrl
          this.parentForm.patchValue({ 'whiteLabelLogoMediaId': media.id })
        }
      })
    )
  }

  clearWhiteLabelLogo(): void {
    this.whiteLabelLogoPreviewUrl = undefined
    this.parentForm.patchValue({ 'whiteLabelLogoMediaId': null })
  }

  changePluginLogo(): void {
    this.subscriptions.add(
      this.openMediaPicker().subscribe(media => {
        if (media) {
          this.pluginLogoPreviewUrl = (media as Media).thumbnailUrl
          this.parentForm.patchValue({ 'pluginLogoMediaId': media.id })
        }
      })
    )
  }

  clearPluginLogo(): void {
    this.pluginLogoPreviewUrl = undefined
    this.parentForm.patchValue({ 'pluginLogoMediaId': null })
  }
}
