import { Type } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HumecloudCommonDecoratorModule } from '../../humecloud-common-decorator.module';
import { KeycloakService } from '../../services/keycloak/keycloak.service';

export function RefreshKeycloakJWTToken(beforeMethodCalled: boolean): MethodDecorator {

  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function () {

      const keycloakService = HumecloudCommonDecoratorModule.injector?.get<KeycloakService>(KeycloakService as Type<KeycloakService>)

      if (beforeMethodCalled) {
        return keycloakService?.getToken(true)
          .pipe(
            map(_ => {
              return original.apply(this, arguments)
            }),
          )
      } else {
        return original.apply(this, arguments)
          .pipe(
            mergeMap(res => {
              return zip(of(res), keycloakService?.getToken(true) as Observable<string>)
            }),
            map(([res, _]) => {
              return res
            })
          )
      }
    };
    return descriptor;
  };

}
