import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { App, Customer, CustomerType, Domain, SliderCheckboxComponent } from 'hcl-lib';
import { SellingCompany } from 'projects/hcl-portal/src/app/common/interfaces/selling-company';
import { SearchableSelectComponent } from '../../searchable-select/searchable-select.component';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'customer-admin-fields',
    templateUrl: './customer-admin-fields.component.html',
    styleUrls: ['./customer-admin-fields.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, TranslateModule, SliderCheckboxComponent, SearchableSelectComponent]
})
export class CustomerAdminFieldsComponent implements OnInit {

  @Input() customer!: Customer;
  @Input() apps!: App[];
  @Input() sellingCompanies!: SellingCompany[];
  @Input() parentForm!: UntypedFormGroup

  domains: { id: Domain, name: string }[] = Object.keys(Domain).map(domain => ({ id: Domain[domain as keyof typeof Domain], name: this.translateService.instant('DOMAIN.' + domain) }))
  customerTypes: { id: CustomerType, name: string }[] = Object.keys(CustomerType).map(customerType => ({ id: CustomerType[customerType as keyof typeof CustomerType], name: this.translateService.instant('ORGANIZATION_TYPE.' + customerType) }))

  adminForm!: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.initializeCustomerData();
  }

  initializeCustomerData() {
    if (!this.customer) {
      const address = { address: "", zipCode: "", city: "", country: "" }
      this.customer = {
        id: "",
        name: "",
        customerId: "",
        appIds: [],
        address: address,
        phoneNumber: "",
        domain: Domain.OTHER,
        metadata: undefined,
        showAncestorEntities: true
      }
    }
    this.adminForm = this.formBuilder.group({
      designer: [this.customer.designer],
      apps: [[]],
      domain: [[]],
      customerType: [[]],
      sellingCompany: [[]],
      showAncestorEntities: [this.customer.showAncestorEntities],
    })
    let appsData = this.apps.map(app => {
      return { id: app.id, name: this.translateService.instant('APPS.' + app.name?.toUpperCase() + '.NAME'), selected: this.customer.appIds?.includes(app.id) }
    })
    let domainsData = this.domains.map(domain => {
      return { id: domain.id, name: domain.name, selected: domain.id == this.customer.domain }
    })
    let customerTypesData = this.customerTypes.map(customerType => {
      return { id: customerType.id, name: customerType.name, selected: customerType.id == this.customer.customerType }
    })
    let sellingCompaniesData = this.sellingCompanies.map(sellingCompany => {
      return { id: sellingCompany.id, name: sellingCompany.name, selected: sellingCompany.id == this.customer.sellingCompanyId }
    })
    this.adminForm.controls['apps'].setValue(appsData)
    this.adminForm.controls['domain'].setValue(domainsData)
    this.adminForm.controls['customerType'].setValue(customerTypesData)
    this.adminForm.controls['sellingCompany'].setValue(sellingCompaniesData)
    this.parentForm.addControl('admin', this.adminForm)
  }
}
