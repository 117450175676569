<mat-card class="mb-3">
  <mat-card-header class="mb-3">
    <mat-card-title>{{ "SET_THEME" | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="mard-card-align">
    <div class="theme-tab">
      <div>
        <form [formGroup]="parentForm">
          <mat-form-field>
            <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.NAME' | translate }}</mat-label>
            <input matInput formControlName="displayName" [value]="customer.displayName">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.SEGMENT_URL' | translate }}</mat-label>
            <input matInput formControlName="domainSegmentName" [value]="customer.domainSegmentName">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'REALM_NAME' | translate }}</mat-label>
            <input matInput formControlName="realmName" [value]="customer.realmName">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'SCREENLAB_GUIDE_URL' | translate }}</mat-label>
            <input matInput formControlName="scrUserGuideUrl" [value]="customer.scrUserGuideUrl">
          </mat-form-field>

          <!-- whiteLabelLogoMediaId -->
          <div *ngIf="customer.id" class="theme-tab__logo">
            <div class="theme-tab__logo__preview">
              <img *ngIf="whiteLabelLogoPreviewUrl; else noLogo" [src]="whiteLabelLogoPreviewUrl">
              <ng-template #noLogo>
                <mat-icon>image</mat-icon>
              </ng-template>
              <button *ngIf="whiteLabelLogoPreviewUrl" mat-icon-button (click)="clearWhiteLabelLogo()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <button mat-raised-button type="button" color="accent" (click)="changeWhiteLabelLogo()"
              [disabled]="!isScopeCustomer"
              [matTooltip]="isScopeCustomer ? null : 'CUSTOMER_FIELDS_COMPONENT.MEDIA_DISABLED' | translate">
              {{ 'CUSTOMER_FIELDS_COMPONENT.SELECT_WHITELABEL_LOGO' | translate }}
            </button>
          </div>

          <!-- pluginLogoMediaId -->
          <div *ngIf="customer.id" class="theme-tab__logo">
            <div class="theme-tab__logo__preview">
              <img *ngIf="pluginLogoPreviewUrl; else noLogo" [src]="pluginLogoPreviewUrl">
              <ng-template #noLogo>
                <mat-icon>image</mat-icon>
              </ng-template>
              <button *ngIf="pluginLogoPreviewUrl" mat-icon-button (click)="clearPluginLogo()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <button mat-raised-button type="button" color="accent" (click)="changePluginLogo()"
              [disabled]="!isScopeCustomer"
              [matTooltip]="isScopeCustomer ? null : 'CUSTOMER_FIELDS_COMPONENT.MEDIA_DISABLED' | translate">
              {{ 'CUSTOMER_FIELDS_COMPONENT.SELECT_PLUGIN_LOGO' | translate }}
            </button>
          </div>

        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>