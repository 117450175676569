import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_SDK_FACTORY } from '@trellisorg/google-places-autocomplete';

/**
 * Uses the `@googlemaps/js-api-loader` to asynchronously load the Google SDK for Maps
 * @param config
 */
async function useLoader(config) {
    return new Loader({
        apiKey: config.apiKey,
        ...config.loaderOptions,
    }).load();
}
function provideGoogleMapsLoader() {
    return {
        provide: GOOGLE_MAPS_SDK_FACTORY,
        useValue: useLoader,
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideGoogleMapsLoader, useLoader };

