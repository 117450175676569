import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpShowError, InvocationResult } from 'hcl-lib';
import { SellingCompanyDto } from 'projects/hcl-portal/src/app/common/interfaces/dto/selling-company-dto';
import { Seller } from 'projects/hcl-portal/src/app/common/interfaces/seller';
import { SellingCompany } from 'projects/hcl-portal/src/app/common/interfaces/selling-company';
import { environment } from 'projects/hcl-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SellingCompanyService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError()
  getSellingCompanies(): Observable<SellingCompany[]> {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/sales').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getSellingCompaniesWithPaging(page: Number, perPage: Number): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/sales', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError(true)
  createSellingCompany(sellingCompany: SellingCompany): Observable<SellingCompany> {
    const sellingCompanyDto: SellingCompanyDto = {
      name: sellingCompany.name
    }
    return this.httpClient.post(environment.cmnAppApiBaseUrl + '/sales', sellingCompanyDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getSellingCompany(id: string): Observable<SellingCompany> | undefined {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/sales/' + id).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  updateSellingCompany(sellingCompany: SellingCompany): Observable<SellingCompany> {
    const sellingCompanyDto: SellingCompanyDto = {
      name: sellingCompany.name
    }
    return this.httpClient.put(environment.cmnAppApiBaseUrl + '/sales/' + sellingCompany.id, sellingCompanyDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  deleteSellingCompany(id: string) {
    return this.httpClient.delete(environment.cmnAppApiBaseUrl + '/sales/' + id)
  }

  @HttpShowError()
  getSellingCompanySellers(id: string): Observable<Seller[]> {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/sales/' + id + '/sellers').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
