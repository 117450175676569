import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Customer, Domain, KeycloakService, ScopeService } from 'hcl-lib';
import { Media, MediaType } from 'projects/hcl-portal/src/app/common/interfaces/media';
import { MediaService } from 'projects/hcl-portal/src/app/common/services/media/media.service';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MediaPickerDialogComponent } from '../../media-picker-dialog/media-picker-dialog.component';
import { NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PlacesAutocompleteModule, providePlacesAutocomplete } from '@trellisorg/google-places-autocomplete';
import { provideGoogleMapsLoader } from '@trellisorg/google-places-autocomplete/loader';

@Component({
  selector: 'customer-fields',
  templateUrl: './customer-fields.component.html',
  styleUrls: ['./customer-fields.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatTooltipModule, MatButtonModule, MatIconModule, TranslateModule, PlacesAutocompleteModule],
  providers: [
    provideGoogleMapsLoader(),
    providePlacesAutocomplete({
      apiKey: 'AIzaSyBfJPYNS-qv3xmE1l8wMZMHdTfE6nzU94E',
      options: {
        componentRestrictions: {
          country: ['fr']
        }
      },
      loaderOptions: {
        libraries: ['places'],
        language: 'fr',
        region: 'FR'
      }
    })
  ]
})
export class CustomerFieldsComponent implements OnInit {

  @Input() customer!: Customer;
  @Input() namePlaceholder!: string;
  @Input() parentForm!: UntypedFormGroup

  @ViewChild("placesRef") placesRef: any;

  subscriptions: Subscription = new Subscription();
  previewUrl?: string

  isScopeCustomer!: boolean
  generalFieldsForm!: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private scopeService: ScopeService,
    private mediaService: MediaService,
    private keycloakService: KeycloakService
  ) {
  }

  ngOnInit(): void {
    this.initializeCustomerData()
  }

  initializeCustomerData() {
    const scopeCustomerId = this.scopeService.getScope()

    if (!this.customer) {
      const address = { address: "", zipCode: "", city: "", country: "" }
      this.customer = {
        id: "",
        name: "",
        customerId: scopeCustomerId,
        appIds: [],
        address: address,
        phoneNumber: this.keycloakService.getPhoneNumber(),
        domain: Domain.OTHER,
        metadata: undefined,
      }
    } else if (this.customer.logoMediaId) {
      this.retrieveMedia(this.customer.logoMediaId)
    }

    this.isScopeCustomer = scopeCustomerId == this.customer.id

    this.generalFieldsForm = this.formBuilder.group({
      name: [this.customer.name, Validators.required],
      address: [this.customer.address.address, Validators.required],
      zipCode: [this.customer.address.zipCode, Validators.required],
      city: [this.customer.address.city, Validators.required],
      country: [this.customer.address.country, Validators.required],
      phoneNumber: [this.customer.phoneNumber],
      phoneNumber2: [this.customer.phoneNumber2],
      comments: [this.customer.comments],
      email: [this.customer.email],
      website: [this.customer.website],
      companyRegistrationNumber: [this.customer.companyRegistrationNumber],
      logoMediaId: [this.customer.logoMediaId],
    })
    this.parentForm.addControl("generalFields", this.generalFieldsForm)
  }

  handleAddressChange(address: google.maps.places.PlaceResult): void {
    const streetNumber = address.address_components?.find(address => address.types.includes('street_number'))?.long_name
    const route = address.address_components?.find(address => address.types.includes('route'))?.long_name
    let addressValue = ""
    if (streetNumber) addressValue += streetNumber + " "
    if (route) addressValue += route
    this.generalFieldsForm.controls['address'].setValue(addressValue)
    this.generalFieldsForm.controls['city'].setValue(
      address.address_components?.find(address => address.types.includes("locality"))?.long_name
    )
    this.generalFieldsForm.controls['zipCode'].setValue(
      address.address_components?.find(address => address.types.includes("postal_code"))?.long_name
    )
    this.generalFieldsForm.controls['country'].setValue(
      address.address_components?.find(address => address.types.includes("country"))?.long_name
    )
    this.placesRef.nativeElement.value = '';
  }

  openMediaPicker(): void {
    var config = new MatDialogConfig();
    config.data = {
      mediaTypes: [MediaType.Image],
      publicMediaTypes: [MediaType.Image],
      actionLabel: this.translateService.instant("MEDIA_PICKER_DIALOG.LABEL.SELECT"),
      hidePublicMediasTab: true,
      hideActivityMediasTab: true
    }
    const mediaPickerDialogRef = this.matDialog.open(MediaPickerDialogComponent, config)
    this.subscriptions.add(
      mediaPickerDialogRef.afterClosed().subscribe(media => {
        if (media) {
          this.previewUrl = (media as Media).thumbnailUrl
          this.generalFieldsForm.patchValue({ 'logoMediaId': media.id })
        }
      })
    )
  }

  clearLogo(): void {
    this.previewUrl = undefined
    this.generalFieldsForm.patchValue({ 'logoMediaId': null })
  }

  retrieveMedia(mediaId: string): void {
    this.subscriptions.add(
      this.scopeService.getScopeCustomer().pipe(
        mergeMap(customer => {
          let customerId = null
          if (this.customer.id != customer?.id) {
            customerId = this.customer.id
          }
          return this.mediaService.getMedia(mediaId, customerId as string)
        })
      ).subscribe(media => {
        this.previewUrl = media.thumbnailUrl
      })
    )
  }
}
