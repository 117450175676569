import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpShowError, InvocationResult, Role, RoleDto } from 'hcl-lib'
import { environment } from 'projects/hcl-portal/src/environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError(true)
  getRoles(): Observable<Role[]> {
    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/roles').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  getRolesWithPaging(pageNumber: number, pageSize: number): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set('perPage', pageSize.toString())
      .set('page', pageNumber.toString());
    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/roles', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError(true)
  getRoleByName(name: string): Observable<Role | null> {
    const options = {
      params: new HttpParams().set('name', name)
    };
    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/roles', options).pipe(
      map((res: any) => {
        return (res as InvocationResult).data as Role[]
      }),
      map(roles => roles.length == 0 ? null : roles[0]) // name is unique
    )
  }

  @HttpShowError(true)
  addRole(role: Role, ownerEmail: string): Observable<Role> {
    const roleDto: RoleDto = {
      name: role.name,
      description: role.description ?? '',
      ownerEmail: ownerEmail,
      customerId: role.customerId,
      permissionIds: role.permissionIds,
      inheritedRoleIds: role.inheritedRoleIds
    }
    return this.httpClient.post(environment.cmnUserApiBaseUrl + '/roles', roleDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  updateRole(role: Role): Observable<Role> {
    const roleDto: RoleDto = {
      name: role.name,
      description: role.description ?? '',
      customerId: role.customerId,
      permissionIds: role.permissionIds,
      inheritedRoleIds: role.inheritedRoleIds
    }
    return this.httpClient.put<Role>(this.buildUri(role.id), roleDto)
  }

  @HttpShowError(true)
  deleteRole(id: string) {
    return this.httpClient.delete(this.buildUri(id))
  }

  buildUri(id: string) {
    return environment.cmnUserApiBaseUrl + '/roles/' + id;
  }
}
