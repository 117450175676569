<div class="customer-fields">
  <div class="customer-fields__bloc">
    <div class="customer-fields__bloc__title">
      {{ 'CUSTOMER_FIELDS_COMPONENT.TITLE' | translate }}
    </div>
    <form [formGroup]="generalFieldsForm" class="customer-fields__bloc__customer-form">
      <mat-form-field>
        <mat-label>{{ namePlaceholder }}</mat-label>
        <input id="nameInput" matInput formControlName="name" [value]="customer.name" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.EMAIL' | translate }}</mat-label>
        <input id="emailInput" matInput formControlName="email" [value]="customer.email">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'WEBSITE' | translate }}</mat-label>
        <input id="websiteInput" matInput formControlName="website" [value]="customer.website">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.SIRET' | translate }}</mat-label>
        <input id="companyRegistrationNumberInput" matInput formControlName="companyRegistrationNumber"
          [value]="customer.companyRegistrationNumber">
      </mat-form-field>

      <div *ngIf="customer.id" class="customer-fields__bloc__logo">
        <div class="customer-fields__bloc__logo__logo-preview">
          <img *ngIf="previewUrl; else noLogo" [src]="previewUrl">
          <ng-template #noLogo>
            <mat-icon>image</mat-icon>
          </ng-template>
          <button *ngIf="previewUrl" mat-icon-button (click)="clearLogo()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <button mat-raised-button type="button" color="accent" (click)="openMediaPicker()" [disabled]="!isScopeCustomer"
          [matTooltip]="isScopeCustomer ? null : 'CUSTOMER_FIELDS_COMPONENT.MEDIA_DISABLED' | translate">
          {{ "CUSTOMER_FIELDS_COMPONENT.SELECT_LOGO" | translate }}
        </button>
      </div>

    </form>
  </div>

  <div class="customer-fields__bloc">
    <div class="customer-fields__bloc__title">
      {{ "CUSTOMER_FIELDS_COMPONENT.HEADQUARTERS_ADDRESS" | translate }}
    </div>
    <div class="customer-fields__bloc__search">
      <span>{{ "CUSTOMER_FIELDS_COMPONENT.SEARCH_ADDRESS" | translate }} :</span>
      <input placesAutocomplete type="text" (placeChanged)="handleAddressChange($event)"
        class="customer-fields__bloc__search__input" #placesRef />
    </div>
    <form [formGroup]="generalFieldsForm" class="customer-fields__bloc__customer-form">
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.ADDRESS' | translate }}</mat-label>
        <input id="addressInput" matInput formControlName="address" [value]="customer.address.address" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.POSTAL_CODE' | translate }}</mat-label>
        <input id="zipCodeInput" matInput formControlName="zipCode" [value]="customer.address.zipCode" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.CITY' | translate }}</mat-label>
        <input id="cityInput" matInput formControlName="city" [value]="customer.address.city" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'CUSTOMER_FIELDS_COMPONENT.COUNTRY' | translate }}</mat-label>
        <input id="countryInput" matInput formControlName="country" [value]="customer.address.country" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PHONE_NUMBER' | translate }}</mat-label>
        <input id="phoneNumberInput" matInput formControlName="phoneNumber" [value]="customer.phoneNumber">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'SECOND_PHONE_NUMBER' | translate }}</mat-label>
        <input id="phoneNumber2Input" matInput formControlName="phoneNumber2" [value]="customer.phoneNumber2">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'COMMENTS' | translate }}</mat-label>
        <textarea id="commentsInput" matInput formControlName="comments" [value]="customer.comments" matTextareaAutosize
          matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
    </form>
  </div>
</div>