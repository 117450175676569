<div class="customer-admin-fields">
  <div class="customer-admin-fields__title">{{"CLIENT_ADMINISTRATION.TITLE" | translate}}</div>
  <div class="customer-admin-fields__bloc">
    <form class="customer-admin-fields__bloc__form" [formGroup]="adminForm">
      <searchable-select panelWidth="auto" *ngIf="apps.length > 0" formControlName="apps" [showSelectArrow]="true"
        placeholder="{{'CLIENT_ADMINISTRATION.SELECT_APPLICATIONS_PLACEHOLDER' | translate}}" [multipleSelect]="true" [stickyPlaceholder]="false">
      </searchable-select>
      <mat-form-field>
        <mat-label>{{'CASE_MANAGER' | translate}}</mat-label>
        <input matInput [formControlName]="'designer'" value="{{ customer.designer }}">
      </mat-form-field>
      <searchable-select panelWidth="auto" formControlName="domain" [showSelectArrow]="true" [placeholder]="'CLIENT_ADMINISTRATION.SELECT_DOMAIN_PLACEHOLDER' | translate"
        [multipleSelect]="false" [stickyPlaceholder]="false">
      </searchable-select>
      <searchable-select panelWidth="auto" formControlName="customerType" [showSelectArrow]="true"
        [placeholder]="'CLIENT_ADMINISTRATION.SELECT_TYPE_OF_SOCIETY_PLACEHOLDER' | translate" [multipleSelect]="false" [stickyPlaceholder]="false">
      </searchable-select>
      <searchable-select panelWidth="auto" *ngIf="sellingCompanies.length > 0" formControlName="sellingCompany" [showSelectArrow]="true"
        [placeholder]="'CLIENT_ADMINISTRATION.SELECT_MANAGER_PLACEHOLDER' | translate" [multipleSelect]="false" [stickyPlaceholder]="false">
      </searchable-select>
      <slider-checkbox [value]="adminForm.value.showAncestorEntities" [parentForm]="adminForm" controlName="showAncestorEntities"
        [label]="'CUSTOMER_FIELDS_COMPONENT.SHOW_ANCESTOR_ENTITIES' | translate">
      </slider-checkbox>
    </form>
  </div>
</div>
