import { NgFor, NgIf, WeekDay } from "@angular/common";
import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from "@angular/core";
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OneshotTimeSlot, TimeSlotType, TimeSlot, WeeklyTimeSlot, ValidatorErrorsComponent } from "hcl-lib";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { TimeSlotUtil } from "../../../../util/timeslot.util";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { NgxMatTimepickerFieldComponent } from "ngx-mat-timepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

export enum CalendarTimeSlotAction {
  CLOSE = "CLOSE",
  DELETE = "DELETE",
  UPDATE = "UPDATE"
}

export const timeSlotValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const type = control.get('type')
  const allDay = control.get('allDay')
  const startTime = control.get('startTime')
  const startDate = control.get('startDate')
  const endTime = control.get('endTime')
  const endDate = control.get('endDate')
  const weekDays = control.get('weekDays')
  return type
    && (allDay && (allDay.value || (!allDay.value && startTime && startTime.value && endTime && endTime.value)))
    && ((type.value == TimeSlotType.WEEKLY && weekDays && weekDays.value.length > 0)
      || (type.value == TimeSlotType.ONESHOT && startDate && startDate.value && endDate && endDate.value))
    ? null : { timeSlotRequiredMissing: true }
}

@Component({
    selector: 'app-calendar-time-slot-dialog',
    templateUrl: './calendar-time-slot-dialog.component.html',
    styleUrls: ['./calendar-time-slot-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, ReactiveFormsModule, MatFormFieldModule, MatDatepickerModule, MatCheckboxModule, MatRadioModule, MatDialogModule, MatButtonModule, MatButtonToggleModule, MatIconModule, TranslateModule, NgxMatTimepickerFieldComponent, ValidatorErrorsComponent]
})
export class CalendarTimeSlotDialogComponent implements OnInit, OnDestroy {

  TimeSlotType = TimeSlotType
  subscriptions: Subscription = new Subscription()

  moment = moment
  weekDays: WeekDay[] = [
    WeekDay.Monday,
    WeekDay.Tuesday,
    WeekDay.Wednesday,
    WeekDay.Thursday,
    WeekDay.Friday,
    WeekDay.Saturday,
    WeekDay.Sunday
  ]

  timeSlot!: TimeSlot
  timeSlotForm!: UntypedFormGroup

  constructor(
    public dialogRef: MatDialogRef<CalendarTimeSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      timeSlotId: string,
      timeSlot: TimeSlot
    },
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: UntypedFormBuilder,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    moment.locale(this.locale)
    this.timeSlot = this.data.timeSlot
    this.timeSlotForm = this.formBuilder.group({
      type: [this.timeSlot.type, Validators.required],
      allDay: [this.timeSlot.allDay, Validators.required],
      startDate: [null],
      startTime: [null],
      endDate: [null],
      endTime: [null],
      weekDays: [this.weekDays]
    }, {
      validators: timeSlotValidator
    })
    this.onTypeChanged()
    if (this.timeSlot.type == TimeSlotType.WEEKLY) {
      let weeklyTimeSlot = this.timeSlot as WeeklyTimeSlot
      this.timeSlotForm.patchValue({
        weekDays: weeklyTimeSlot.weekDays?.map(day => TimeSlotUtil.getDayOfWeekMoment(day))
      })
      if (!this.timeSlot.allDay && typeof weeklyTimeSlot.startTime === 'string' && typeof weeklyTimeSlot.endTime === 'string') {
        this.timeSlotForm.patchValue({
          startTime: TimeSlotUtil.getTimeWithoutSeconds(weeklyTimeSlot.startTime),
          endTime: TimeSlotUtil.getTimeWithoutSeconds(weeklyTimeSlot.endTime)
        })
      }
    } else if (this.timeSlot.type == TimeSlotType.ONESHOT) {
      let oneShotTimeSlot = this.timeSlot as OneshotTimeSlot
      if (typeof oneShotTimeSlot.start !== 'undefined' && typeof oneShotTimeSlot.end !== 'undefined') {
        this.timeSlotForm.patchValue({
          startDate: TimeSlotUtil.getDateAtTime(oneShotTimeSlot.start, "00:00"),
          startTime: TimeSlotUtil.getTimeWithoutSecondsFromDate(oneShotTimeSlot.start),
          endDate: TimeSlotUtil.getDateAtTime(oneShotTimeSlot.end, "00:00"),
          endTime: TimeSlotUtil.getTimeWithoutSecondsFromDate(oneShotTimeSlot.end),
          weekDays: []
        })
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  onTypeChanged() {
    const type: TimeSlotType = this.timeSlotForm.get('type')?.value
    if (type == TimeSlotType.WEEKLY) {
      this.timeSlotForm.patchValue({
        startDate: null,
        endDate: null
      })
      this.timeSlotForm.get('weekDays')?.addValidators(Validators.required)
      this.timeSlotForm.get('startDate')?.clearValidators()
      this.timeSlotForm.get('startDate')?.updateValueAndValidity()
      this.timeSlotForm.get('endDate')?.clearValidators()
      this.timeSlotForm.get('endDate')?.updateValueAndValidity()
    } else if (type == TimeSlotType.ONESHOT) {
      this.timeSlotForm.patchValue({
        weekDays: []
      })
      this.timeSlotForm.get('startDate')?.addValidators(Validators.required)
      this.timeSlotForm.get('endDate')?.addValidators(Validators.required)
      this.timeSlotForm.get('weekDays')?.clearValidators()
      this.timeSlotForm.get('weekDays')?.updateValueAndValidity()
    }
  }

  onSubmit(): void {
    if (this.timeSlotForm.valid) {
      let timeSlot: TimeSlot | null = null
      const type = this.timeSlotForm.get("type")?.value
      const allDay = this.timeSlotForm.get("allDay")?.value
      const startTime = allDay ? "00:00" : this.timeSlotForm.get("startTime")?.value
      const endTime = allDay ? "23:59" : this.timeSlotForm.get("endTime")?.value
      if (type == TimeSlotType.WEEKLY) {
        timeSlot = {
          type: type,
          allDay: allDay,
          weekDays: this.timeSlotForm.get("weekDays")?.value.map((dayNb: number) => TimeSlotUtil.getDayOfWeek(dayNb)),
          startTime: TimeSlotUtil.getTimeWithSecondsFromTimePickerFormat(startTime),
          endTime: TimeSlotUtil.getTimeWithSecondsFromTimePickerFormat(endTime)
        } as WeeklyTimeSlot
      } else if (type == TimeSlotType.ONESHOT) {
        const startDate = this.timeSlotForm.get("startDate")?.value
        const endDate = this.timeSlotForm.get("endDate")?.value
        timeSlot = {
          type: type,
          allDay: allDay,
          start: TimeSlotUtil.getDateAtTime(startDate, TimeSlotUtil.getTimeWithSecondsFromTimePickerFormat(startTime)),
          end: TimeSlotUtil.getDateAtTime(endDate, TimeSlotUtil.getTimeWithSecondsFromTimePickerFormat(endTime))
        } as OneshotTimeSlot
      }
      if (timeSlot) {
        this.timeSlot = timeSlot
      }
      this.doClose(CalendarTimeSlotAction.UPDATE)
    }
  }

  doDelete(): void {
    this.doClose(CalendarTimeSlotAction.DELETE)
  }

  doClose(action: CalendarTimeSlotAction): void {
    this.dialogRef.close({
      action: action,
      timeSlot: this.timeSlot
    })
  }

  onClose(): void {
    this.doClose(CalendarTimeSlotAction.CLOSE)
  }

  clearDates() {
    this.timeSlotForm.patchValue({
      startDate: null,
      endDate: null,
    })
  }
}
