import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpShowError, InvocationResult, Permission } from 'hcl-lib'
import { environment } from 'projects/hcl-portal/src/environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError(true)
  getPermissions(): Observable<Permission[]> {
    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/permissions').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
