import { NgIf } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class LoaderComponent {

  loading = false;
}
