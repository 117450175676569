import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { HttpShowError, InvocationResult } from 'hcl-lib';
import { LicenseTypeDto } from 'projects/hcl-portal/src/app/common/interfaces/dto/license-type-dto';
import { LicenseType } from 'projects/hcl-portal/src/app/common/interfaces/license-type';
import { environment } from 'projects/hcl-portal/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicenseTypeService {

  constructor(private httpClient: HttpClient) { }

  @HttpShowError(true)
  getLicenseTypes(): Observable<LicenseType[] | null> {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/licenseTypes').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  getLicenseTypesByAppId(appId: string): Observable<LicenseType[]> {
    const queryParams = new HttpParams().set('appId', appId)
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/licenseTypes', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  getLicenseTypesWithPaging(page: Number, perPage: Number): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/licenseTypes', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError(true)
  createLicenseType(licenseType: LicenseType): Observable<LicenseType> {
    const licenseTypeDto: LicenseTypeDto = {
      appId: licenseType.appId,
      name: licenseType.name,
      type: licenseType.type,
      start: licenseType.start,
      end: licenseType.end,
      quota: licenseType.quota,
      period: licenseType.period,
      trial: false
    }
    return this.httpClient.post(environment.cmnAppApiBaseUrl + '/licenseTypes', licenseTypeDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  getLicenseType(id: string): Observable<LicenseType> {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/licenseTypes/' + id).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  updateLicenseType(licenseType: LicenseType): Observable<LicenseType> {
    const licenseTypeDto: LicenseTypeDto = {
      appId: licenseType.appId,
      name: licenseType.name,
      type: licenseType.type,
      start: licenseType.start,
      end: licenseType.end,
      quota: licenseType.quota,
      period: licenseType.period,
      trial: licenseType.trial
    }
    return this.httpClient.put(environment.cmnAppApiBaseUrl + '/licenseTypes/' + licenseType.id, licenseTypeDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  deleteLicenseType(id: string) {
    return this.httpClient.delete(environment.cmnAppApiBaseUrl + '/licenseTypes/' + id)
  }
}
