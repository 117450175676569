import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { DatePipe, NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, formatDate } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Injectable, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CalendarModule, CalendarDateFormatter, CalendarDayViewBeforeRenderEvent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarEventTimesChangedEventType, CalendarEventTitleFormatter, CalendarMonthViewBeforeRenderEvent, CalendarView, CalendarWeekViewBeforeRenderEvent, DateFormatterParams } from "angular-calendar";
import { DayOfWeek, OneshotTimeSlot, TimeSlotType, TimeSlot, WeeklyTimeSlot, ContextMenuAttachDirective, ContextMenuComponent } from "hcl-lib";
import { fromEvent, Subject, Subscription } from "rxjs";
import { ViewPeriod, WeekViewHourSegment } from 'calendar-utils';
import * as _moment from "moment";
import { default as _rollupMoment } from 'moment';
import { RRule, Weekday } from "rrule";
import { WeeklyScenarioTimeSlot, OneshotScenarioTimeSlot } from "projects/hcl-portal/src/app/screenlab/interfaces/scenario-time-slot";
import { TimeSlotUtil } from "../../../../util/timeslot.util";
import { addDays, addMinutes, endOfWeek } from "date-fns";
import { finalize, takeUntil } from "rxjs/operators";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { CalendarTimeSlotAction, CalendarTimeSlotDialogComponent } from "../calendar-time-slot-dialog/calendar-time-slot-dialog.component";
import { v4 as uuidv4 } from 'uuid'
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

const moment = _rollupMoment || _moment;

interface TimeSlotMeta {
  timeSlotId: string,
  timeSlot: TimeSlot,
  creating: boolean
}

@Injectable()
export class CustomCalendarDateFormatter extends CalendarDateFormatter {

  public override dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale as string);
  }

  public override weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}

@Injectable()
export class CustomCalendarEventTitleFormatter extends CalendarEventTitleFormatter {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super()
  }

  override week(event: CalendarEvent<TimeSlotMeta>): string {
    return this.formatTitle(event)
  }

  override day(event: CalendarEvent<TimeSlotMeta>): string {
    return this.formatTitle(event)
  }

  formatTitle(event: CalendarEvent<TimeSlotMeta>): string {
    let title = event.title
    if (!event.meta?.creating && !event.allDay && typeof event.end !== 'undefined') {
      title += `${formatDate(event.start, 'HH:mm', this.locale)} - ${formatDate(event.end, 'HH:mm', this.locale)}`
    }
    return title
  }
}

const CALENDAR_RESPONSIVE = {
  small: {
    breakpoint: '(max-width: 576px)',
    view: CalendarView.Day,
  },
  medium: {
    breakpoint: '(max-width: 768px)',
    view: CalendarView.Week,
  },
  large: {
    breakpoint: '(max-width: 960px)',
    view: CalendarView.Week,
  },
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomCalendarDateFormatter
    }, {
      provide: CalendarEventTitleFormatter,
      useClass: CustomCalendarEventTitleFormatter
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgIf, NgSwitch, NgSwitchCase, NgClass, NgStyle, DatePipe, MatButtonModule, MatIconModule, MatTooltipModule, CalendarModule, TranslateModule, ContextMenuAttachDirective]
})
export class CalendarComponent implements OnInit, OnDestroy, OnChanges {

  subscriptions: Subscription = new Subscription()

  @Input() title!: string
  @Input() disabled: boolean = false
  @Input() timeSlots: TimeSlot[] = []
  @Input() backgroundTimeSlots: TimeSlot[] = []

  view: CalendarView = CalendarView.Week
  viewDate = new Date()
  viewPeriod?: ViewPeriod
  previousViewPeriod?: ViewPeriod
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1
  hourSegments = 2
  hourSegmentHeight = 14

  timeSlotMenu!: ContextMenuComponent
  timeSlotMetasMap: Map<string, TimeSlotMeta> = new Map<string, TimeSlotMeta>()
  calendarEvents: CalendarEvent<TimeSlotMeta>[] = []
  creatingCalendarEvents: CalendarEvent<TimeSlotMeta>[] = []

  refresh$: Subject<any> = new Subject()

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.initCalendarView()
    this.initTimeSlots()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['disabled']) {
      this.calendarEvents = []
    }
  }

  initCalendarView(): void {
    this.subscriptions.add(
      this.breakpointObserver.observe(
        Object.values(CALENDAR_RESPONSIVE).map(({ breakpoint }) => breakpoint)
      ).subscribe((state: BreakpointState) => {
        const foundBreakpoint = Object.values(CALENDAR_RESPONSIVE).find(
          ({ breakpoint }) => !!state.breakpoints[breakpoint]
        );
        if (foundBreakpoint) {
          this.view = foundBreakpoint.view
        } else {
          this.view = CalendarView.Week
        }
        this.changeDetectorRef.markForCheck()
      })
    )
  }

  initTimeSlots(): void {
    if (this.timeSlots) {
      this.timeSlots.forEach(timeSlot => {
        const timeSlotMeta: TimeSlotMeta = {
          timeSlotId: uuidv4(),
          timeSlot: timeSlot,
          creating: false
        }
        this.addTimeSlotInternal(timeSlotMeta)
      })
    }
  }

  eventTimesChanged({ event, newStart, newEnd, allDay, type }: CalendarEventTimesChangedEvent): void {
    if (type == CalendarEventTimesChangedEventType.Drop) {
      // todo ? there is no drop atm
    } else {
      // CalendarEventTimesChangedEventType.Drag
      // CalendarEventTimesChangedEventType.Resize
      const timeSlot = event.meta.timeSlot
      this.updateTimeSlotStartEnd(timeSlot, newStart, newEnd)
    }
    this.updateCalendarEvents(true)
  }

  eventClicked({ event }: { event: CalendarEvent<TimeSlotMeta> }): void {
    if (typeof event.meta !== 'undefined') {
      this.doEventUpdate(event.meta)
    }
  }

  addCalendarEvent(
    calendarEvents: CalendarEvent[],
    title: string,
    allDay: boolean,
    start: Date,
    end: Date | null,
    textColor: string,
    backgroundColor?: string,
    cssClass?: string,
    meta?: TimeSlotMeta
  ) {
    let startDate = start
    let endDate = end
    if (allDay) {
      startDate.setHours(0, 0, 0, 0)
      endDate = new Date(startDate)
      endDate.setHours(23, 59, 59, 999)
    }
    calendarEvents.push({
      title: title,
      allDay: false,
      start: startDate,
      end: endDate,
      color: { primary: textColor, secondary: backgroundColor },
      draggable: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      left: 0,
      width: 100,
      meta: meta,
      actions: [],
      cssClass: cssClass
    } as CalendarEvent)
  }

  updateCalendarEvents(force: boolean) {
    if (!this.viewPeriod || (!force && this.sameViewPeriods(this.viewPeriod, this.previousViewPeriod))) return
    let calendarEvents: CalendarEvent<any>[] = []
    const periodStartMoment = moment(this.viewPeriod.start).startOf('day')
    const periodEndMoment = moment(this.viewPeriod.end).endOf('day')
    for (let timeSlotMeta of this.timeSlotMetasMap.values()) {
      const timeSlot = timeSlotMeta.timeSlot
      const backgroundColor = this.stringToColor(timeSlotMeta.timeSlotId)
      const textColor = this.getReadableTextCssColorForBackgroundColor(backgroundColor)
      if (timeSlot.type == TimeSlotType.WEEKLY) {
        const weeklyTimeSlot = timeSlot as WeeklyTimeSlot
        let rruleWeekDays = []
        for (let weekDay of weeklyTimeSlot.weekDays as DayOfWeek[]) {
          rruleWeekDays.push(this.mapToRRuleWeekDay(weekDay))
        }
        const rrule: RRule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: rruleWeekDays,
          dtstart: TimeSlotUtil.formatToLocalDateTime(periodStartMoment.toDate()),
          until: TimeSlotUtil.formatToLocalDateTime(periodEndMoment.toDate())
        })
        rrule.all().forEach((date) => {
          let startDate = TimeSlotUtil.getDateAtTime(date, weeklyTimeSlot.startTime as string)
          let endDate = null
          if (!weeklyTimeSlot.allDay) {
            endDate = TimeSlotUtil.getDateAtTime(date, weeklyTimeSlot.endTime as string)
          }
          if (endDate != null && endDate.getHours() == 0 && endDate.getMinutes() == 0) {
            endDate.setHours(23, 59, 59, 999)
          }
          this.addCalendarEvent(calendarEvents, "", weeklyTimeSlot.allDay, startDate, endDate, textColor, backgroundColor, undefined, timeSlotMeta)
        })
      } else if (timeSlot.type == TimeSlotType.ONESHOT) {
        const oneshotTimeSlot = timeSlot as OneshotTimeSlot
        const startMoment = moment(oneshotTimeSlot.start)
        const endMoment = moment(oneshotTimeSlot.end)
        if (startMoment.isBetween(periodStartMoment, periodEndMoment, undefined, '[]')
          || (endMoment && endMoment.isBetween(periodStartMoment, periodEndMoment, undefined, '(]'))
          || (periodStartMoment.isBetween(startMoment, endMoment, undefined, '[]') && periodEndMoment.isBetween(startMoment, endMoment, undefined, '[]'))) {
          this.addCalendarEvent(calendarEvents, "", false, startMoment.toDate(), endMoment.toDate(), textColor, backgroundColor, '', timeSlotMeta)
        }
      }
    }

    calendarEvents.push(...this.creatingCalendarEvents)
    this.calendarEvents = calendarEvents
    this.forceRefresh()
    this.changeDetectorRef.detectChanges()
  }

  forceRefresh() {
    this.refresh$.next(null);
  }

  private stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // convert int to rgb
    let c = (hash & 0x00FFFFFF).toString(16).toUpperCase()
    return "#" + ("00000".substring(0, 6 - c.length) + c);
  }

  private getReadableTextCssColorForBackgroundColor(hexColor: string): string {
    const color = hexColor.replace("#", "");
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  private mapToRRuleWeekDay(weekDay: DayOfWeek): Weekday {
    switch (weekDay) {
      case DayOfWeek.MONDAY: {
        return RRule.MO
      }
      case DayOfWeek.TUESDAY: {
        return RRule.TU
      }
      case DayOfWeek.WEDNESDAY: {
        return RRule.WE
      }
      case DayOfWeek.THURSDAY: {
        return RRule.TH
      }
      case DayOfWeek.FRIDAY: {
        return RRule.FR
      }
      case DayOfWeek.SATURDAY: {
        return RRule.SA
      }
      case DayOfWeek.SUNDAY: {
        return RRule.SU
      }
    }
  }

  startDragToCreateTimeSlot(segment: WeekViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLDivElement): void {
    if (this.disabled) return
    const event: CalendarEvent<TimeSlotMeta> = this.createWeeklyTimeSlotCalendarEvent()
    if (typeof event.meta !== 'undefined') {
      event.meta.timeSlot.allDay = false
      event.start = segment.date
      event.end = addMinutes(segment.date, 15)
      event.meta.creating = true
      this.updateTimeSlotStartEnd(event.meta?.timeSlot, event.start, event.end)
      this.creatingCalendarEvents.push(event)
      this.updateCalendarEvents(true)

      const segmentPosition = segmentElement.getBoundingClientRect()
      const endOfView = endOfWeek(this.viewDate, {
        weekStartsOn: this.weekStartsOn
      })

      this.subscriptions.add(
        fromEvent(document, 'mousemove').pipe(
          finalize(() => {
            if (typeof event.meta !== 'undefined') {
              event.meta.creating = false
              this.creatingCalendarEvents = []
              this.addTimeSlotInternal(event.meta)
              this.updateCalendarEvents(true)
              this.doEventUpdate(event.meta, true)
            }
          }),
          takeUntil(fromEvent(document, 'mouseup'))
        ).subscribe((e: Event) => {
          const mouseMoveEvent = e as MouseEvent
          const minutesDiff = Math.max(30, (ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top,
            segmentPosition.height) / segmentPosition.height) * 30)
          const daysDiff = floorToNearest(mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width) / segmentPosition.width

          const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff)
          if (newEnd > segment.date && newEnd < endOfView) {
            event.end = newEnd;
          }

          const timeSlot = event.meta?.timeSlot

          if (typeof timeSlot !== 'undefined') {
            timeSlot.allDay = moment(event.start).startOf('day').isSame(moment(event.start))
              && moment(event.end).endOf('day').isSame(moment(event.end))
            const oldIsWeekly = timeSlot.type == TimeSlotType.WEEKLY
            const newIsWeekly = moment(event.start).startOf('day').isSame(moment(event.end).startOf('day'))
            if (oldIsWeekly != newIsWeekly) {
              if (newIsWeekly) {
                timeSlot.type = TimeSlotType.WEEKLY
              } else {
                timeSlot.type = TimeSlotType.ONESHOT
              }
            }
            this.updateTimeSlotStartEnd(timeSlot, event.start, newEnd)
          }

          this.forceRefresh()
        })
      )
    }
  }

  private createWeeklyTimeSlotCalendarEvent(): CalendarEvent<TimeSlotMeta> {
    return {
      title: "",
      start: new Date,
      draggable: true,
      meta: this.createWeeklyTimeSlotScenarioTimeSlotMeta()
    }
  }

  private createWeeklyTimeSlotScenarioTimeSlotMeta(): TimeSlotMeta {
    return {
      timeSlotId: uuidv4(),
      timeSlot: {
        type: TimeSlotType.WEEKLY,
        allDay: true,
        weekDays: [DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY, DayOfWeek.THURSDAY, DayOfWeek.FRIDAY]
      } as WeeklyTimeSlot,
      creating: false
    }
  }

  addTimeSlotInternal(timeSlotMeta: TimeSlotMeta) {
    this.timeSlotMetasMap.set(timeSlotMeta.timeSlotId, timeSlotMeta)
  }

  private updateTimeSlotStartEnd(timeSlot: TimeSlot, newStart: Date, newEnd?: Date) {
    if (timeSlot.type == TimeSlotType.WEEKLY) {
      const weeklyTimeSlot = timeSlot as WeeklyScenarioTimeSlot
      weeklyTimeSlot.startTime = newStart.toLocaleTimeString('fr-FR')
      weeklyTimeSlot.endTime = newEnd?.toLocaleTimeString('fr-FR')
    } else if (timeSlot.type == TimeSlotType.ONESHOT) {
      const oneShotTimeSlot = timeSlot as OneshotScenarioTimeSlot
      oneShotTimeSlot.allDay = false
      oneShotTimeSlot.start = newStart
      oneShotTimeSlot.end = newEnd
    }
  }

  doEventUpdate(timeSlotMeta: TimeSlotMeta, deleteWhenClose: boolean = false): void {
    const config = new MatDialogConfig()
    const timeSlotId = timeSlotMeta.timeSlotId;
    const timeSlot = timeSlotMeta.timeSlot;
    config.data = {
      timeSlotId: timeSlotId,
      timeSlot: timeSlot
    }
    const timeSlotUpdateDialogRef = this.matDialog.open(CalendarTimeSlotDialogComponent, config)
    this.subscriptions.add(
      timeSlotUpdateDialogRef.afterClosed().subscribe((result: { action: CalendarTimeSlotAction, timeSlot: TimeSlot }) => {
        if (result) {
          if (result.action == CalendarTimeSlotAction.DELETE || (deleteWhenClose && result.action == CalendarTimeSlotAction.CLOSE)) {
            this.deleteTimeSlot(timeSlotId)
          } else if (result.action == CalendarTimeSlotAction.UPDATE) {
            this.updateTimeSlot(timeSlotId, result.timeSlot)
          }
        } else {
          if (deleteWhenClose) {
            this.deleteTimeSlot(timeSlotId)
          }
        }
      })
    )
  }

  private deleteTimeSlot(timeSlotId: string) {
    this.timeSlotMetasMap.delete(timeSlotId)
    this.updateCalendarEvents(true)
  }

  private updateTimeSlot(timeSlotId: string, timeSlot: TimeSlot) {
    let oldTimeSlotMeta = this.timeSlotMetasMap.get(timeSlotId)
    if (typeof oldTimeSlotMeta !== 'undefined') {
      oldTimeSlotMeta.timeSlot = timeSlot
      this.updateCalendarEvents(true)
    }
  }

  getTimeSlots(): TimeSlot[] {
    return Array.from(this.timeSlotMetasMap.values()).map(timeSlotMeta => {
      const timeSlot = timeSlotMeta.timeSlot
      if (timeSlot.type == TimeSlotType.ONESHOT) {
        const oneshotTimeSlot = timeSlot as OneshotTimeSlot
        if (typeof oneshotTimeSlot.start !== 'undefined') {
          oneshotTimeSlot.start = TimeSlotUtil.formatToLocalDateTime(oneshotTimeSlot.start)
        }
        if (typeof oneshotTimeSlot.end !== 'undefined') {
          oneshotTimeSlot.end = TimeSlotUtil.formatToLocalDateTime(oneshotTimeSlot.end)
        }
      }
      return timeSlot
    })
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent): void {
    this.updateViewPeriod(renderEvent)
    renderEvent.hourColumns.forEach(hourColumn => {
      hourColumn.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          if (this.isInBackgroundHours(segment)) {
            segment.cssClass = 'background-hour'
          }
        })
      })
    })
  }

  updateViewPeriod(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    if (!this.viewPeriod || !this.sameViewPeriods(this.viewPeriod, viewRender.period)) {
      this.previousViewPeriod = this.viewPeriod
      this.viewPeriod = viewRender.period
      this.updateCalendarEvents(false)
    }
  }

  sameViewPeriods(vp1?: ViewPeriod, vp2?: ViewPeriod): boolean {
    if (vp1 && vp2) {
      return moment(vp1.start).isSame(vp2.start) && moment(vp1.end).isSame(vp2.end)
    } else {
      return !vp1 && vp1 == vp2
    }
  }

  isInBackgroundHours(segment: WeekViewHourSegment): boolean {
    return this.backgroundTimeSlots.some(timeSlot => {
      if (timeSlot.type == TimeSlotType.ONESHOT) {
        const oneshotTimeSlot = timeSlot as OneshotTimeSlot
        return (typeof oneshotTimeSlot.start !== 'undefined' && segment.date >= oneshotTimeSlot.start)
          && (typeof oneshotTimeSlot.end !== 'undefined' && segment.date < oneshotTimeSlot.end)
      } else if (timeSlot.type == TimeSlotType.WEEKLY) {
        const weeklyTimeSlot = timeSlot as WeeklyTimeSlot
        const segmentDayOfWeek = TimeSlotUtil.getDayOfWeekOfDate(segment.date)
        if (weeklyTimeSlot.weekDays?.includes(segmentDayOfWeek as DayOfWeek)) {
          const weekDayDate = TimeSlotUtil.getDateOfDayOfWeekFromDate(segmentDayOfWeek as DayOfWeek, segment.date)
          if (weeklyTimeSlot.allDay) {
            const startDate = TimeSlotUtil.getDateAtTime(weekDayDate, "00:00")
            const endDate = TimeSlotUtil.getDateAtTime(weekDayDate, "23:59")
            return segment.date >= startDate && segment.date < endDate
          } else {
            const startDate = TimeSlotUtil.getDateAtTime(weekDayDate, weeklyTimeSlot.startTime as string)
            const endDate = TimeSlotUtil.getDateAtTime(weekDayDate, weeklyTimeSlot.endTime as string)
            return segment.date >= startDate && segment.date < endDate
          }
        }
      }
      return false
    })
  }
}
