import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Customer, CustomerDto, HttpShowError, InvocationResult, Loader, RefreshKeycloakJWTToken } from 'hcl-lib'
import { environment } from 'projects/hcl-portal/src/environments/environment'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private httpClient: HttpClient
  ) {
    // do nothing
  }

  @HttpShowError()
  getCustomers(pageNumber: number, pageSize: number, sort: string | undefined = undefined, customerId: string | undefined = undefined, search: string | undefined = undefined): Observable<InvocationResult> {
    let queryParams = new HttpParams()
      .set('perPage', pageSize.toString())
      .set('page', pageNumber.toString())

    if (customerId) {
      queryParams = queryParams.append('customerId', customerId)
    }

    if (search && search != "") {
      queryParams = queryParams.append('search', search)
    }

    if (sort) {
      queryParams = queryParams.append('sort', sort)
    }

    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/customers', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getCustomerByName(name: string): Observable<Customer | null> {
    const queryParams = new HttpParams()
      .set('name', name.toString());
    return this.httpClient.get(environment.cmnUserApiBaseUrl + '/customers/', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data[0]
      }),
      catchError(_ => {
        return of(null)
      })
    )
  }

  @HttpShowError(true)
  addCustomer(customer: Customer): Observable<Customer> {
    const customerDto: CustomerDto = {
      name: customer.name,
      customerId: customer.customerId,
      appIds: customer.appIds,
      address: customer.address,
      phoneNumber: customer.phoneNumber,
      phoneNumber2: customer.phoneNumber2,
      domain: customer.domain,
      comments: customer.comments,
      email: customer.email,
      logoMediaId: customer.logoMediaId,
      website: customer.website,
      companyRegistrationNumber: customer.companyRegistrationNumber,
      designer: customer.designer,
      customerType: customer.customerType,
      sellingCompanyId: customer.sellingCompanyId,
      openingTimeSlots: customer.openingTimeSlots,
      updatingTimeSlots: customer.updatingTimeSlots,
      metadata: customer.metadata,
      displayName: customer.displayName,
      whiteLabelLogoMediaId: customer.whiteLabelLogoMediaId,
      pluginLogoMediaId: customer.pluginLogoMediaId,
      domainSegmentName: customer.domainSegmentName,
      realmName: customer.realmName,
      scrUserGuideUrl: customer.scrUserGuideUrl
    }
    return this.httpClient.post(environment.cmnUserApiBaseUrl + '/customers', customerDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @RefreshKeycloakJWTToken(false)
  @HttpShowError(true)
  @Loader(true)
  updateCustomer(customer: Customer): Observable<Customer> {
    const customerDto: CustomerDto = {
      name: customer.name,
      customerId: customer.customerId,
      appIds: customer.appIds,
      address: customer.address,
      phoneNumber: customer.phoneNumber,
      phoneNumber2: customer.phoneNumber2,
      domain: customer.domain,
      comments: customer.comments,
      email: customer.email,
      logoMediaId: customer.logoMediaId,
      website: customer.website,
      companyRegistrationNumber: customer.companyRegistrationNumber,
      designer: customer.designer,
      customerType: customer.customerType,
      sellingCompanyId: customer.sellingCompanyId,
      openingTimeSlots: customer.openingTimeSlots,
      updatingTimeSlots: customer.updatingTimeSlots,
      metadata: customer.metadata,
      displayName: customer.displayName,
      whiteLabelLogoMediaId: customer.whiteLabelLogoMediaId,
      pluginLogoMediaId: customer.pluginLogoMediaId,
      domainSegmentName: customer.domainSegmentName,
      realmName: customer.realmName,
      scrUserGuideUrl: customer.scrUserGuideUrl,
      showAncestorEntities: customer.showAncestorEntities,
    }
    return this.httpClient.put(environment.cmnUserApiBaseUrl + '/customers/' + customer.id, customerDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  deleteCustomer(id: string): Observable<any> {
    return this.httpClient.delete(environment.cmnUserApiBaseUrl + '/customers/' + id)
  }
}
