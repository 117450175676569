import { Injectable } from "@angular/core"
import { HttpClient, HttpParams } from "@angular/common/http"
import { HttpShowError, InvocationResult } from "hcl-lib"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { environment } from "../../../../environments/environment"
import { EventTypeDto } from "../../interfaces/dto/event-type-dto"
import { EventType } from "../../interfaces/event-type"

@Injectable({
  providedIn: "root"
})
export class EventTypeService {
  constructor(
    private httpClient: HttpClient
  ) { }

  @HttpShowError()
  getEventTypesWithPaging(
    page: number,
    perPage: number,
  ): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set("perPage", perPage.toString())
      .set("page", page.toString())
    return this.httpClient.get(
      `${environment.cmnEventApiBaseUrl}/event-types`,
      { params: queryParams }
    ).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getEventType(
    eventTypeKey: string
  ): Observable<EventTypeDto> {
    return this.httpClient.get(`${environment.cmnEventApiBaseUrl}/event-types/${eventTypeKey}`).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  updateEventType(
    eventType: EventType
  ): Observable<EventTypeDto> {
    return this.httpClient.put(`${environment.cmnEventApiBaseUrl}/event-types/${eventType.id}`, eventType).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  deleteEventType(
    id: string
  ): Observable<object> {
    return this.httpClient.delete(`${environment.cmnEventApiBaseUrl}/event-types/${id}`)
  }
}
