import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Customer, CustomerService } from "hcl-lib";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NgIf } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomerRecentSearchSelectComponent } from '../../../../Layout/Components/widget/customer-recent-search-select/customer-recent-search-select.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-customer-migrate-dialog',
    templateUrl: './customer-migrate-dialog.component.html',
    styleUrls: ['./customer-migrate-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatDialogModule, MatButtonModule, TranslateModule, CustomerRecentSearchSelectComponent]
})
export class CustomerMigrateDialogComponent implements OnInit {

  subscriptions: Subscription = new Subscription()

  custMigrateForm!: UntypedFormGroup

  currentParentCustomer!: Customer

  constructor(
    private customerService: CustomerService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CustomerMigrateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public customer: Customer,
  ) { }

  ngOnInit(): void {
    this.custMigrateForm = this.formBuilder.group({
      customerId: [null, Validators.required]
    })
    this.retrieveParentCustomer(this.customer.customerId as string)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveParentCustomer(customerId: string): void {
    this.subscriptions.add(
      this.customerService.getCustomer(customerId).subscribe(customer => {
        this.currentParentCustomer = customer
      })
    )
  }

  confirm(): void {
    if (this.custMigrateForm.valid) {
      this.subscriptions.add(
        this.customerService.changeParentCustomer(
          this.customer.id as string,
          this.custMigrateForm.value.customerId
        ).subscribe(migratedCustomer => {
          this.dialogRef.close(migratedCustomer)
        })
      )
    }
  }

  onSelectedCustomer(customer: Customer): void {
    this.custMigrateForm.patchValue({ customerId: customer.id })
  }
}
