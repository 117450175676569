import { JsonPipe, Location, NgIf } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { App, CmnIfPermDirective, Customer, CustomerService as CustomerServiceCommon, ScopeService, SnackBarService, TimeSlot } from 'hcl-lib';
import { SellingCompany } from 'projects/hcl-portal/src/app/common/interfaces/selling-company';
import { AppService } from 'projects/hcl-portal/src/app/common/services/app/app.service';
import { CustomerService } from 'projects/hcl-portal/src/app/common/services/customer/customer.service';
import { SellingCompanyService } from 'projects/hcl-portal/src/app/common/services/selling-company/selling-company.service';
import { Subscription, zip } from 'rxjs';
import { CalendarComponent } from '../../../Layout/Components/widget/calendar/calendar.component';
import { CustomerFieldsComponent } from '../../../Layout/Components/widget/customer/customer-fields/customer-fields.component';
import { ThemeTabComponent } from '../../../Layout/Components/widget/customer/theme-tab/theme-tab.component';
import { MatDialog } from "@angular/material/dialog";
import { CustomerMigrateDialogComponent } from "../customer-migrate-dialog/customer-migrate-dialog/customer-migrate-dialog.component";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomerAdminFieldsComponent } from '../../../Layout/Components/widget/customer/customer-admin-fields/customer-admin-fields.component';

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    standalone: true,
    imports: [NgIf, JsonPipe, MatToolbarModule, MatButtonModule, MatIconModule, MatCardModule, MatTabsModule, TranslateModule, CmnIfPermDirective, CustomerFieldsComponent, CustomerAdminFieldsComponent, ThemeTabComponent, CalendarComponent]
})
export class CustomerDetailsComponent implements OnInit {

  @ViewChild(CustomerFieldsComponent) customerFieldsComponent!: CustomerFieldsComponent
  @ViewChild(ThemeTabComponent) themeTabComponent!: ThemeTabComponent
  @ViewChild("openingHoursCalendar") openingHoursCalendar!: CalendarComponent
  @ViewChild("updatingHoursCalendar") updatingHoursCalendar!: CalendarComponent

  customerForm: UntypedFormGroup = new UntypedFormGroup({});
  subscriptions: Subscription = new Subscription();
  customer!: Customer;
  apps!: App[];
  sellingCompanies!: SellingCompany[];
  namePlaceholder!: string
  canUpdate: boolean = false
  canUpdateAdmin: boolean = false

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private customerServiceCommon: CustomerServiceCommon,
    private appService: AppService,
    private sellingCompanyService: SellingCompanyService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private scopeService: ScopeService,
    private location: Location,
    private matDialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(_ => {
      this.retrieveCustomer()
    })
    this.namePlaceholder = this.translateService.instant('CUSTOMER_DETAIL_COMPONENT.NAME_PLACEHOLDER')
    this.retrieveCustomer()
    this.retrieveApps()
    this.retrieveSellingCompanies()
    this.subscriptions.add(
      zip(
        this.scopeService.hasPermission('cmn:customer:update:unrestricted'),
        this.scopeService.hasPermission('cmn:customer:update:admin')
      ).subscribe(([canUpdateUnrestricted, canUpdateAdmin]) => {
        this.canUpdate = canUpdateUnrestricted || canUpdateAdmin
        this.canUpdateAdmin = canUpdateAdmin
      })
    )
  }

  retrieveSellingCompanies() {
    this.subscriptions.add(this.sellingCompanyService.getSellingCompanies().subscribe(sellingCompanies => {
      this.sellingCompanies = sellingCompanies;
    }))
  }

  retrieveApps() {
    this.subscriptions.add(this.appService.getApps().subscribe(apps => {
      this.apps = apps
    }))
  }

  retrieveCustomer() {
    this.subscriptions.add(this.customerServiceCommon.getCustomer(this.route.snapshot.paramMap.get('id') as string).subscribe(customer => {
      this.customer = customer
    }))
  }

  updateCustomer() {
    let domain = this.customer.domain
    if (this.canUpdateAdmin) {
      let adminForm = this.customerForm.get('admin') as AbstractControl
      domain = (adminForm.get('domain')?.value && adminForm.get('domain')?.value[0])
        ? adminForm.get('domain')?.value[0] : null
      this.customer.appIds = adminForm.get('apps')?.value
      this.customer.designer = adminForm.get('designer')?.value
      this.customer.customerType = (adminForm.get('customerType')?.value && adminForm.get('customerType')?.value[0])
        ? adminForm.get('customerType')?.value[0] : null
      this.customer.sellingCompanyId = (adminForm.get('sellingCompany')?.value && adminForm.get('sellingCompany')?.value[0])
        ? adminForm.get('sellingCompany')?.value[0] : null
      this.customer.showAncestorEntities = adminForm.get('showAncestorEntities')?.value
    }
    let generalFieldsForm = this.customerForm.get('generalFields') as AbstractControl
    this.customer.name = generalFieldsForm.get('name')?.value
    this.customer.address.address = generalFieldsForm.get('address')?.value
    this.customer.address.zipCode = generalFieldsForm.get('zipCode')?.value
    this.customer.address.city = generalFieldsForm.get('city')?.value
    this.customer.address.country = generalFieldsForm.get('country')?.value
    const phoneNumber = generalFieldsForm.get('phoneNumber')?.value;
    this.customer.phoneNumber = phoneNumber ? phoneNumber : ""
    this.customer.phoneNumber2 = generalFieldsForm.get('phoneNumber2')?.value
    this.customer.domain = domain
    this.customer.comments = generalFieldsForm.get('comments')?.value
    this.customer.email = generalFieldsForm.get('email')?.value
    this.customer.website = generalFieldsForm.get('website')?.value
    this.customer.companyRegistrationNumber = generalFieldsForm.get('companyRegistrationNumber')?.value
    this.customer.logoMediaId = generalFieldsForm.get('logoMediaId')?.value
    this.customer.openingTimeSlots = this.getOpeningTimeSlots()
    this.customer.updatingTimeSlots = this.getUpdatingTimeSlots()
    this.customer.displayName = this.customerForm.get('displayName')?.value
    this.customer.whiteLabelLogoMediaId = this.customerForm.get('whiteLabelLogoMediaId')?.value
    this.customer.pluginLogoMediaId = this.customerForm.get('pluginLogoMediaId')?.value
    this.customer.domainSegmentName = this.customerForm.get('domainSegmentName')?.value
    this.customer.realmName = this.customerForm.get('realmName')?.value
    this.customer.scrUserGuideUrl = this.customerForm.get('scrUserGuideUrl')?.value
    if (!domain) {
      this.snackBarService.snackBarError('CUSTOMER_FIELDS_COMPONENT.SELECT_DOMAIN')
    } else {
      this.subscriptions.add(
        this.customerService.updateCustomer(this.customer).subscribe(updatedCustomer => {
          if (this.scopeService.getScope() == updatedCustomer.id) {
            this.scopeService.updateScope(updatedCustomer)
          }
          this.goBack()
        })
      )
    }
  }

  goBack() {
    this.location.back()
  }

  getOpeningTimeSlots(): TimeSlot[] {
    return this.openingHoursCalendar.getTimeSlots()
  }

  getUpdatingTimeSlots(): TimeSlot[] {
    return this.updatingHoursCalendar.getTimeSlots()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  openCustomerMigrateDialog(): void {
    const deviceMigrateDialogRef = this.matDialog.open(CustomerMigrateDialogComponent, {
      data: this.customer
    })
    this.subscriptions.add(
      deviceMigrateDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(["/"])
        }
      })
    )
  }
}
